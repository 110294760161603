import React from 'react';
import { Box, Typography, Button, Grid  } from '@mui/material';
import { styled } from '@mui/system';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Link } from 'react-router-dom';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import BrightnessHighTwoToneIcon from '@mui/icons-material/BrightnessHighTwoTone';
import QueryBuilderTwoToneIcon from '@mui/icons-material/QueryBuilderTwoTone';
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone';


// Sample image URL
const imageUrl1 = './images/homeImage.jpg';
const imageUrl2 = './images/homeImage1.jpeg';

// Styled Box component for the image with text overlay and opaque overlay
const ImageBox1 = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '600px',
  backgroundImage: `url(${imageUrl1})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  justifyContent: 'left',
  color: 'white',
  textShadow: '2px 2px 4px rgba(0,0,0,0.7)',
  margin: '0', // Ensure no margin around the box
  paddingLeft: '0', // Ensure no padding around the box
  borderRadius: '0 0 15% 0 / 0 0 40% 0',
  '&::before': {
    content: '""',
    opacity: "80%",
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#096eec', // Adjust the color and opacity as needed
    zIndex: 1,
    borderRadius: '0 0 15% 0 / 0 0 40% 0',
  },
  '& > *': {
    position: 'relative',
    zIndex: 2,
  },
});

const ImageBox2 = styled(Box)({
  position: 'relative',
  width: '100%',
  height: 'auto',
  backgroundImage: `url(${imageUrl2})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  justifyContent: 'left',
  color: 'white',
  margin: '0', // Ensure no margin around the box
  paddingLeft: '0', // Ensure no padding around the box
  '&::before': {
    content: '""',
    opacity: "90%",
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#096eec', // Adjust the color and opacity as needed
    zIndex: 1,
  },
  '& > *': {
    position: 'relative',
    zIndex: 2,
  },
});

const HomePage = () => {
  return (
    <>
      <ImageBox1>
      <Typography
      variant="h2"
      component="div"
      sx={{
        marginLeft: { xs: 2, sm: 10, md: 20, lg: 30 }, // Adjust margin for different screen sizes
        textAlign: { xs: 'center', sm: 'left' }, // Center text on small screens
      }}
    >
      Welcome to Weber <br />
      <span
        style={{
          fontFamily: 'Matura MT Script Capitals',
          display: 'block', // Ensures proper line break handling on small screens
        }}
      >
        Online Academy
      </span>
      <br />
      <span
        style={{
          fontSize: '18px',
          fontWeight: 300,
          display: 'block',
        }}
      >
        Empowering Nurses, Enriching Careers: Elevate Your Expertise with Weber Online Academy.
      </span>
      <br />
      <Button
        variant="contained"
        component={Link}
        to="/Register"
        sx={{
          borderRadius: 5,
          paddingX: { xs: 2, sm: 5 }, // Adjust padding for different screens
          paddingY: 1,
          fontSize: { xs: '14px', sm: '16px' }, // Adjust button font size
          backgroundColor: '#09ad46',
          '&:hover': {
            backgroundColor: '#057531', // Change to the desired hover color
          },
          marginTop: 2, // Add spacing for better layout on small screens
        }}
      >
        Enroll Now!!
      </Button>
    </Typography>
      </ImageBox1>
      
      <Grid container spacing={2} sx={{ marginTop: 10, marginBottom: 10 }}>
      {/* Course Title */}
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: { xs: 'center', sm: 'center', md: 'center', lg: 'left' },
          textAlign: { xs: 'center', sm: 'left' }, // Align text center on small screens
        }}
      >
        <Typography
          variant="h2"
          component="div"
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.5rem', md: '3rem' }, // Adjust font size for responsiveness
            marginLeft: { xs: 2, sm: 10, md: 20, lg: 30 }, 
          }}
        >
          <span style={{ fontFamily: 'Matura MT Script Capitals' }}>Our Courses</span>
        </Typography>
      </Grid>

      {/* Course List */}
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          textAlign: { xs: 'center', sm: 'left' },
          marginTop: { xs: 2, sm: 0 }, // Add spacing for better layout on small screens
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontSize: { xs: '1rem', sm: '1.25rem' }, // Responsive font size
          }}
        >
          <VerifiedUserIcon
            style={{
              color: 'green',
              verticalAlign: 'middle',
              marginRight: '0.5em',
            }}
          />
          Defense Medical Exam Training
          <br />
          <VerifiedUserIcon
            style={{
              color: 'green',
              verticalAlign: 'middle',
              marginRight: '0.5em',
            }}
          />
          Defense Medical Exam Observation Practice
          <br />
          <VerifiedUserIcon
            style={{
              color: 'green',
              verticalAlign: 'middle',
              marginRight: '0.5em',
            }}
          />
          Unethical Defense Medical Exam Challenges
        </Typography>
      </Grid>
    </Grid>

    <ImageBox2>
  <Grid
    container
    spacing={2}
    sx={{
      padding: { xs: 2, sm: 4, md: 10, lg: 20 }, // Adjust padding for different screen sizes
    }}
  >
    {/* Experience Section */}
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      sx={{
        paddingRight: { xs: 0, sm: 2, md: 4 }, // Adjust padding for responsiveness
        textAlign: { xs: 'center', md: 'left' }, // Center text for smaller screens
      }}
    >
      <h1>Experience</h1>
      <p>
        Experience education like never before. Enjoy the flexibility of
        learning at your own pace, at any time. Connect with fellow learners and
        instructors through discussion forums and podcasts, making your
        educational journey interactive and enriching.
      </p>
    </Grid>

    {/* Education Section */}
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      sx={{
        paddingRight: { xs: 0, sm: 2, md: 4 },
        textAlign: { xs: 'center', md: 'left' },
      }}
    >
      <h1>Education</h1>
      <p>
        Our academy offers a wide range of courses tailored to meet the needs of
        learners at every level. Whether you're looking to develop new skills
        or deepen your knowledge in a specific area, our expert instructors
        provide comprehensive and up-to-date educational content.
      </p>
    </Grid>

    {/* Certificate Section */}
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      sx={{
        paddingRight: { xs: 0, sm: 2, md: 4 },
        textAlign: { xs: 'center', md: 'left' },
      }}
    >
      <h1>Certificate</h1>
      <p>
        Upon successful completion of your course, you will receive a verified
        certificate that you can share with your professional network. Our
        certifications are recognized by industry leaders and can significantly
        enhance your resume, showcasing your commitment to continuous learning
        and professional development.
      </p>
    </Grid>

    {/* Call-to-Action Section */}
    <Grid
      item
      xs={15}
      sm={6}
      md={3}
      sx={{
        backgroundColor: 'white',
        color: 'black',
        padding: { xs: 3, sm: 4, md: 5 },
        maxWidth: { xs: '100%', sm: '100%', md: '400px' }, // Adjust width for responsiveness
        marginBottom: { xs: 2, sm: 0 },
        marginTop: { xs: 2, sm: 0 },
        marginLeft: { xs: 2, sm: 0 },
        textAlign: 'center', // Center-align for smaller screens
      }}
    >
      <h3 style={{ color: '#fcad03' }}>Study at Your Own Pace</h3>
      <h3>Boost Your Career by Learning Skills in High Demand</h3>
      <Link
        component={Link}
        to="/Courses/"
        sx={{
          color: 'green',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        <ArrowRightIcon
          style={{
            color: 'green',
            verticalAlign: 'middle',
            marginRight: '0.5em',
          }}
        />
        Get Started
      </Link>
    </Grid>
  </Grid>
</ImageBox2>


<Grid 
  container 
  spacing={2} 
  sx={{ 
    marginTop: { xs: 5, md: 10 }, 
    marginBottom: { xs: 5, md: 10 }, 
    paddingX: { xs: 2, sm: 4, md: 0 } 
  }}
>
  {/* Left Section */}
  <Grid 
    item 
    xs={12} 
    md={6} 
    sx={{ 
      display: 'flex', 
      justifyContent: { xs: 'flex-start', md: 'center' }, 
      textAlign: { xs: 'center', md: 'left' },
    }}
  >
    <Typography 
      variant="h5" 
      component="div" 
      sx={{ 
        textAlign: { xs: 'center', md: 'left' } 
      }}
    >
      <h4 style={{ marginBottom: -10 }}>Features of Our Courses</h4>
      <h1>Why Choose Us?</h1>
      <small>
        Choose us for expert instructors, cutting-edge content,
        <br /> and a seamless, engaging learning experience tailored to your needs.
      </small>
    </Typography>
  </Grid>

  {/* Right Section */}
  <Grid 
    item 
    xs={12} 
    md={6} 
    sx={{ 
      textAlign: 'left', 
      marginTop: { xs: 2, md: 4 }, 
      paddingRight: { xs: 2, md: 10 },
    }}
  >
    <Typography variant="h6" component="div">
      {/* Feature 1 */}
      <div style={{ marginBottom: 15, display: 'flex', alignItems: 'flex-start' }}>
        <BrightnessHighTwoToneIcon 
          style={{ 
            color: '#fcad03', 
            marginRight: '0.5em', 
            fontSize: 40 
          }} 
        />
        <div>
          <strong>Best Industry Leaders</strong>
          <small style={{ display: 'block' }}>
            Unlock your potential by learning from the best industry leaders, who bring real-world experience and expertise to every course.
          </small>
        </div>
      </div>

      {/* Feature 2 */}
      <div style={{ marginBottom: 15, display: 'flex', alignItems: 'flex-start' }}>
        <QueryBuilderTwoToneIcon 
          style={{ 
            color: '#fcad03', 
            marginRight: '0.5em', 
            fontSize: 40 
          }} 
        />
        <div>
          <strong>Learn Online at Your Own Pace</strong>
          <small style={{ display: 'block' }}>
            Learn anytime and anywhere, ensuring flexibility in your education journey.
          </small>
        </div>
      </div>

      {/* Feature 3 */}
      <div style={{ marginBottom: 15, display: 'flex', alignItems: 'flex-start' }}>
        <BusinessCenterTwoToneIcon 
          style={{ 
            color: '#fcad03', 
            marginRight: '0.5em', 
            fontSize: 40 
          }} 
        />
        <div>
          <strong>Professional Certification</strong>
          <small style={{ display: 'block' }}>
            Gain certifications recognized by industry leaders to boost your resume and professional credibility.
          </small>
        </div>
      </div>
    </Typography>
  </Grid>
</Grid>

    </>
  );
};

export default HomePage;
