import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, Drawer, List, ListItem, ListItemText, Container, Link as MuiLink, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LogoutIcon from '@mui/icons-material/Logout';

function App(props) {
  const { content } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoggedIn = localStorage.getItem('token') !== null;

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    // Remove the token from localStorage to logout the user
    localStorage.removeItem('token');
    // Redirect to the login page or any other desired page
    window.location.href = '/';
  };

  const drawerContent = (
    <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
      <List>
        <ListItem component={Link} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem component={Link} to="/About/">
          <ListItemText primary="About"  />
        </ListItem>
        <ListItem component={Link} to="/Books/">
          <ListItemText  primary="Books" />
        </ListItem>
        <ListItem component={Link} to="/Documents/">
          <ListItemText  primary="BuyDocs" />
        </ListItem>
        <ListItem component={Link} to="/Podcasts/">
          <ListItemText primary="Buy Podcast" />
        </ListItem>
        <ListItem component={Link} to="/Courses/">
          <ListItemText primary="Courses" />
        </ListItem>
        {isLoggedIn && (
                <ListItem component={Link} to="/MyAcademy/"><ListItemText primary="My Academy" /></ListItem>
              )}
        {isLoggedIn && (
                <ListItem component={Link} to="/MyDocuments/"><ListItemText primary="My Docs" /></ListItem>
              )}
      </List>
    </Box>
  );

  return (
    <div className="App">
      {/* Top Bar for Address and Contact Info */}
      <Box sx={{ backgroundColor: "#096eec", color: "#ffffff", borderBottom: "1px solid #609ef0" }}>
        <Container maxWidth="lg" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="body1">
            Have a question? | <a href="tel:1020123458" style={{ color: "#ffffff", textDecoration: "none" }}>310-340-9618</a> | <a href="mailto:silvia@weberlegalnurse.com" style={{ color: "#ffffff", textDecoration: "none" }}>silvia@weberlegalnurse.com</a>
          </Typography>
          <Box>
            {/* Conditionally render login/register or logout button */}
            {isLoggedIn ? (
              <Button color="inherit" onClick={handleLogout}>
                <LogoutIcon sx={{ fontSize: 18 }} />
                Logout
              </Button>
            ) : (
              <>
                <Button color="inherit" component={Link} to="/Login">
                  <LockIcon sx={{ fontSize: 18 }} />
                  Log In
                </Button>
                <Button color="inherit" component={Link} to="/Register"><PersonIcon sx={{ fontSize: 18 }} />Register</Button>
              </>
            )}
          </Box>
        </Container>
      </Box>
      <AppBar position="sticky" sx={{ backgroundColor: "#096eec" }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'left' }}>
            <Link to="/">
              <img src="/Weber Online Academy.png" alt="Logo" style={{ height: 65 }} />
            </Link>
          </Typography>
          {isMobile ? (
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          ) : (
            <Box sx={{ display: 'flex' }}>
              <Button color="inherit" component={Link} to="/">Home</Button>
              <Button color="inherit" component={Link} to="/About/" >About</Button>
              <Button color="inherit" component={Link} to="/Books/" >Books</Button>
              <Button color="inherit" component={Link} to="/Documents/" >Buy Docs</Button>
              <Button color="inherit" component={Link} to="/Podcasts/">Podcast</Button>
              <Button color="inherit" component={Link} to="/Courses/">Courses</Button>
              
              {isLoggedIn && (
                <Button color="inherit" component={Link} to="/MyAcademy/">My Academy</Button>
              )}
              {isLoggedIn && (
                <Button color="inherit" component={Link} to="/MyDocuments/">My Docs</Button>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {content}
      </Box>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        {drawerContent}
      </Drawer>
      <Box
  sx={{
    backgroundColor: "#096eec",
    color: "#ffffff",
    paddingBottom: { xs: 3, md: 5 },
    paddingTop: { xs: 2, md: 3 },
    textAlign: { xs: "center", md: "left" },
    borderTop: "1px solid #609ef0",
    marginTop: "auto",
    paddingLeft: { xs: 2, md: 20 },
    paddingRight: { xs: 2, md: 20 },
  }}
>
  <Grid container spacing={4}>
    {/* Logo and description */}
    <Grid item xs={12} md={6}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: { xs: "center", md: "flex-start" } }}>
        <Link to="/">
          <img src="/Weber Online Academy.png" alt="Logo" style={{ height: 70 }} />
        </Link>
        <Typography
          sx={{
            textAlign: { xs: "center", md: "left" },
            marginTop: { xs: 2, md: 0 },
          }}
        >
          At Weber Legal Nurse Consulting Inc., our team brings a wealth of comprehensive and up-to-date industry
          expertise to every case. With a track record of efficiency and excellence, we prioritize ensuring that all
          tasks are completed to the highest standard.
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{
          marginTop: 2,
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <MuiLink href="/PrivacyPolicy/" color="inherit" underline="none">
          Privacy Policy
        </MuiLink>{" "}
        |{" "}
        <MuiLink href="/TermsOfService/" color="inherit" underline="none">
          Terms of Service
        </MuiLink>
      </Typography>
      <Typography variant="body2" sx={{ textAlign: { xs: "center", md: "left" } }}>
        &copy; {new Date().getFullYear()} Weber Online Academy. All rights reserved.
      </Typography>
    </Grid>

    {/* Popular Courses */}
    <Grid item xs={12} md={3}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: { xs: "center", md: "flex-start" } }}>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 1,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <h4>Popular Courses</h4>
          <Link href="#" style={{ textDecoration: "none", color: "white" }}>
            Defense Medical Exam Training
          </Link>
          <br />
          <Link href="#" style={{ textDecoration: "none", color: "white" }}>
            Defense Medical Exam Observation Practice
          </Link>
          <br />
          <Link href="#" style={{ textDecoration: "none", color: "white" }}>
            Unethical Defense Medical Exam Challenges
          </Link>
          <br />
        </Typography>
      </Box>
    </Grid>

    {/* Contact Us */}
    <Grid item xs={12} md={3}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: { xs: "center", md: "flex-start" } }}>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 1,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <h4>Contact Us</h4>
          <Typography variant="body" component="div" sx={{ textAlign: { xs: "center", md: "left" } }}>
            <div style={{ marginBottom: 5 }}>
              <PlaceIcon style={{ color: "white", verticalAlign: "middle", marginRight: "0.1em" }} /> 6921 Jack Rabbit
              Way, Palmdale, CA 93552
            </div>
            <div style={{ marginBottom: 5 }}>
              <LocalPhoneIcon style={{ color: "white", verticalAlign: "middle", marginRight: "0.1em" }} /> 310-340-9618
            </div>
            <div style={{ marginBottom: 5 }}>
              <EmailIcon style={{ color: "white", verticalAlign: "middle", marginRight: "0.1em" }} />{" "}
              silvia@weberlegalnurse.com
            </div>
          </Typography>
        </Typography>
        <Box sx={{ display: "flex", gap: 1, justifyContent: { xs: "center", md: "flex-start" } }}>
          <MuiLink href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" color="inherit">
            <FacebookIcon />
          </MuiLink>
          <MuiLink href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" color="inherit">
            <TwitterIcon />
          </MuiLink>
          <MuiLink href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" color="inherit">
            <InstagramIcon />
          </MuiLink>
        </Box>
      </Box>
    </Grid>
  </Grid>
</Box>

    </div>
  );
}

export default App;
